<template>
  <div id="user-list">
    <!-- app drawer -->
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <particular-case-form
        v-model="item"
        :is-form-active="isFormActive"
        @refetch-data="hideForm"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un cas particulier') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
      </particular-case-form>
    </v-dialog>
    <!-- source total card -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Cas particuliers") }}<v-chip outlined class="ml-2">{{ $store.getters['particularCase/total'] }}</v-chip>
      </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="$store.state.particularCase.field.label"
          :placeholder="$t('Rechercher')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @input="searchItems"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="openDialog()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t("Ajouter un Cas particulier") }}</span>
          </v-btn>

          <!--          <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>-->
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        dense
        :headers="headers"
        :items="$store.getters['particularCase/particular_cases']"
        :options.sync="options"
        :page.sync="$store.getters['particularCase/current_page']"
        :server-items-length="$store.getters['particularCase/total']"
        :loading="loading"
        hide-default-footer
        show-select
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        @page-count="pageCount = $event"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            icon
            small
          >
            <v-icon
              size="20"
              @click="openDialog(item)"
            >
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="error"
          >
            <v-icon
              size="20"
              color="error"
              @click="deleteItem(item)"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="$store.getters['particularCase/current_page']"
              total-visible="6"
              :length="$store.getters['particularCase/last_page']"
              @input="getParticularCase"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
} from '@mdi/js'
import {onUnmounted, ref, onMounted} from '@vue/composition-api'

// sidebar
//import { avatarText } from '@core/utils/filter'
import store from '@/store'
import ParticularCaseForm from "@/views/apps/particularCase/ParticularCaseForm";
//import userStoreModule from './itemStoreModule'

//import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import {mapGetters} from "vuex";

export default {
  name: "ParticularCase",
  components: {
    RowsPerPage,
    ParticularCaseForm,
  },

  computed: {
    ...mapGetters('particularCase', ['current_page', 'total', 'last_page', 'per_page', 'particular_cases']),
  },

  setup() {
    const item = ref({})
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const loading = ref(false)
    const isFormActive  = ref(false)
    const selectedRows = ref([])
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const options = ref({
      rowsPerPage: 10,
      page: 1,
      sortDesc: [true],
    })

    const save = d => {
      menuref.value.save(d)
    }

    const searchItems = () => {
      setTimeout(() => {
        getParticularCase(store.getters["particularCase/current_page"])
      }, 500)
    }

    const openDialog = (val) => {
      if (val) item.value = val
      else {
        item.value = {id: null, label: null}
        //resetForm()
      }
      isFormActive.value = !isFormActive.value
    }

    const deleteItem = (item) => {
      loading.value = true
      store.dispatch("particularCase/deleteParticularCase", item).then(() => {
        loading.value = false
      }).catch(error => {
        console.log(error)
        loading.value = false
      })
    }

    const getParticularCase = (page = 1) => {
      isFormActive.value = false
      loading.value = true
      store.dispatch("particularCase/getParticularCasesList",
        {
          page: page,
          field: store.state.particularCase.field
        }).then(() => {
          loading.value = false
      }).catch(error => {
        loading.value = false
      })
    }

    const hideForm = () => {
      isFormActive.value = !isFormActive.value
    }

    onMounted(getParticularCase(store.getters["particularCase/current_page"]))


    return {
      hideForm,
      searchItems,
      isFormActive,
      item,
      isSnackbarBottomVisible,
      snackBarMessage,
      options,
      openDialog,
      getParticularCase,
      save,
      deleteItem,
      loading,
      date,
      pageCount: 0,
      menu,
      selectedRows,
      picker,
      menuref,
      headers: [
        {text: 'Libellé', value: 'label'},
        {text: 'ACTIONS', value: 'actions',align: 'center', sortable: false},
      ],
      itemsPerPage: parseInt(store.state.particularCase.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
